import React from "react";
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import "../styles/layout.css";

const ContentWrapper = styled.div`
  min-height: calc(100vh - 85px);
  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 135px);
  }
`;

const FooterWrapper = styled.div``;

const Layout = ({ children, siteTitle, location }) => (
  <div>
    <ContentWrapper>
      <Header siteTitle={siteTitle} location={location} />
      <div>{children}</div>
    </ContentWrapper>
    <Footer />
  </div>
);

export default Layout;
