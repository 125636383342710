import Menu from "react-burger-menu/lib/menus/slide";
import React from "react";
import { Link } from "gatsby";

import "./mobile-menu.scss";

export default function MobileMenu({ rawNavItems }) {
  const navItems = rawNavItems.map((item) => {
    if (item._type === "navItemReference" && !item.openInNewTab) {
      return (
        <Link
          to={`/${item.referencedDocument.slug.current}/`}
          key={item._key}
          className="bm-menu-item"
        >
          {item.label}
        </Link>
      );
    }

    if (item._type === "navItemReference" && item.openInNewTab) {
      return (
        <a
          key={item._key}
          href={`/${item.referencedDocument.slug.current}/`}
          className="bm-menu-item"
          target="_blank"
          rel="noreferrer noopener"
        >
          {item.label}
        </a>
      );
    }

    if (item._type === "navItemNonReference" && !item.openInNewTab) {
      return (
        <a href={`${item.url}`} key={item._key} className="bm-menu-item">
          {item.label}
        </a>
      );
    }

    if (item._type === "navItemNonReference" && item.openInNewTab) {
      return (
        <a
          href={`${item.url}`}
          key={item._key}
          className="bm-menu-item"
          target="_blank"
          rel="noreferrer noopener"
        >
          {item.label}
        </a>
      );
    }
  });
  return (
    <Menu right disableAutoFocus>
      <Link key={"home"} id="home" className="bm-menu-item" to="/">
        Home
      </Link>
      {/* Primary Nav items are going here */}
      {navItems}
      <Link key={"latest"} id="latest" className="bm-menu-item" to="/latest/">
        Latest
      </Link>
      <Link key={"archive"} id="archive" className="bm-menu-item" to="/archive/">
        Archive
      </Link>
    </Menu>
  );
}
