import styled from "styled-components";

export const MaxWidth = styled.div`
  max-width: 1072px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.9rem;

  @media only screen and (max-width: 449px) {
    padding: 0 0.65rem;
  }
`;

export const PostPreviewTitle = styled.h2`
  color: rgba(0, 0, 0, 0.84);
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.25rem;

  @media only screen and (max-width: 480px) {
    font-size: 1.65rem;
  }
`;
export const PostPreviewExcerpt = styled.p`
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 1rem;
`;

export const PageTitle = styled.h1`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.68);
  margin: 0 0 1.45rem 0;
  font-size: 2.9rem;

  @media only screen and (max-width: 480px) {
    text-align: center;
    font-size: 2.65rem;
  }
`;

export const PageDivider = styled.hr`
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 1.25rem 0;
`;

export const FlexRow = styled.div`
  display: flex;

  > :first-child {
    padding-left: 0;
  }

  > :last-child {
    padding-right: 0;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Col66 = styled.div`
  width: 66.66%;
  padding: 0 0.5rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
    width 100%;
  }
`;

export const Col33 = styled.div`
  width: 33.33%;
  padding: 0 0.5rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

export const SmallDivider = styled.hr`
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0.5rem 0 0.75rem;
`;

export const SmallText = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.68);
  margin-bottom: 1rem;
  line-height: 1.4;

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    border-bottom: 2px solid rgb(73, 126, 107);
    padding: 1px 2px 0;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline-width: 0;
      color: #000;
      background-color: rgba(73, 126, 107, 0.5);
      border-bottom: 2px solid rgba(73, 126, 107, 0.5) !important;
    }
  }
`;

export const HideOnMobile = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const ShowOnMobile = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const PickerSorterWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: 600px) {
    > :first-child {
      width: 60%;
      min-width: 355px;
    }

    > :last-child {
      width: 30%;
    }
  }

  @media only screen and (max-width: 599px) {
    flex-direction: column;
  }
`;
