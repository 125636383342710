import { Link, graphql, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { MaxWidth } from "./ui-components";

import MobileMenu from "./mobile-menu";
import TwitterSvg from "../images/twitter.svg";
import FacebookSvg from "../images/facebook.svg";
import InstagramSvg from "../images/instagram.svg";

const NavBar = styled.nav`
  min-height: 54px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  ul,
  ul li {
    margin: 0;
  }

  ul li {
    display: flex;
    align-items: center;

    a {
      text-transform: uppercase;
      font-size: 15px;
      text-decoration: none;

      padding: 0.5rem 0.8rem;
      @media only screen and (max-width: 651px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      margin: 0 0.2rem;

      color: #bad5c9;

      transition: color 0.3s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

      &:visited {
        color: #bad5c9;
      }
      &:hover,
      &:active,
      &:focus {
        color: white;
      }

      letter-spacing: 1px;
    }
  }
`;

const PrimaryNav = styled.ul`
  display: flex;
  list-style: none;

  > li:first-child a {
    padding-left: 0;
    margin-left: 0;
  }

  .home-image-link {
    a {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;

      img {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const MobileMenuWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 601px) {
    display: none;
  }
`;

const SocialNav = styled.ul`
  display: flex;

  li {
    a {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
      display: flex;

      svg {
        height: 20px;
        width: auto;
        fill: #bad5c9;

        transition: fill 0.3s;
        transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      }

      &:visited {
        svg {
          fill: #bad5c9;
        }
      }

      &:hover,
      &:active,
      &:focus {
        svg {
          fill: white;
        }
      }
    }
  }
`;

// Fix sticky behavior on mobile

export default function navbar({ location }) {
  return (
    <StaticQuery
      query={NAV_QUERY}
      render={(data) => {
        const { twitter, instagram, facebook } = data.sanitySiteSettings.contactInfo;

        const navItems = data.sanitySiteSettings.primaryNav.map((item) => {
          return (
            <li key={item._key}>
              {item._type === "navItemReference" && !item.openInNewTab && (
                <Link to={`/${item.referencedDocument.slug.current}/`}>{item.label}</Link>
              )}

              {item._type === "navItemReference" && item.openInNewTab && (
                <a
                  href={`/${item.referencedDocument.slug.current}/`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.label}
                </a>
              )}

              {item._type === "navItemNonReference" && !item.openInNewTab && (
                <a href={`${item.url}`}>{item.label}</a>
              )}

              {item._type === "navItemNonReference" && item.openInNewTab && (
                <a href={`${item.url}`} target="_blank" rel="noreferrer noopener">
                  {item.label}
                </a>
              )}
            </li>
          );
        });

        return (
          <div style={{ backgroundColor: "#035642" }}>
            <MaxWidth>
              <NavBar>
                <PrimaryNav>
                  <li className="home-image-link">
                    <Link to="/">Home</Link>
                  </li>
                  {navItems}
                </PrimaryNav>
                <SocialNav>
                  {twitter ? (
                    <li>
                      <a
                        href={`${twitter}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="our twitter page"
                      >
                        <TwitterSvg />
                      </a>
                    </li>
                  ) : null}
                  {facebook ? (
                    <li>
                      <a
                        href={`${facebook}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="our facebook page"
                      >
                        <FacebookSvg />
                      </a>
                    </li>
                  ) : null}
                  {instagram ? (
                    <li>
                      <a
                        href={`${instagram}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label="our instagram page"
                      >
                        <InstagramSvg />
                      </a>
                    </li>
                  ) : null}
                </SocialNav>
                <MobileMenuWrapper>
                  <MobileMenu rawNavItems={data.sanitySiteSettings.primaryNav} />
                </MobileMenuWrapper>
              </NavBar>
            </MaxWidth>
          </div>
        );
      }}
    />
  );
}

export const NAV_QUERY = graphql`
  query NavQuery {
    sanitySiteSettings {
      primaryNav {
        ... on SanityNavItemNonReference {
          _key
          _type
          label
          url
          openInNewTab
        }
        ... on SanityNavItemReference {
          _key
          _type
          label
          openInNewTab
          referencedDocument {
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              slug {
                current
              }
            }
          }
        }
      }
      contactInfo {
        facebook
        twitter
        instagram
      }
    }
  }
`;
