import { Link, graphql, StaticQuery } from "gatsby";
import React from "react";
import { MaxWidth } from "./ui-components";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import styled from "styled-components";
import Navbar from "./navbar.js";

const Wrapper = styled.div`
  padding: 1.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.not-homepage {
    min-height: unset;
  }

  .subheading {
    color: rgba(255, 255, 255);
    font-size: 1.45rem;
  }

  .subheading {
    margin-bottom: 0;
    margin-top: 1rem;
    line-height: 1.4;
  }

  @media only screen and (min-width: 481px) {
    padding: 1.5rem 0;
    min-height: 12rem;
  }

  @media only screen and (max-width: 480px) {
    .subheading {
      font-size: 20px;
      text-align: center;
    }

    align-items: center;
  }
`;

const LogoWrapper = styled.div`
  max-width: 276px;
  width: 100%;
`;

const Header = ({ siteTitle, location }) => {
  return (
    <StaticQuery
      query={HEADER_QUERY}
      render={(data) => {
        return (
          <div style={{ marginBottom: "1.25rem" }}>
            <BackgroundImage
              fluid={data.LocalHeaderBackground.childImageSharp.fluid}
              backgroundColor={`#035642`}
              loading={"eager"}
              fadeIn={false}
            >
              <MaxWidth>
                <Wrapper className={location === "/" ? "homepage" : "not-homepage"}>
                  <LogoWrapper>
                    <Link to="/">
                      <Img
                        fluid={data.LocalHeaderImages.childImageSharp.fluid}
                        alt={"Inside Cal Poly Logo"}
                        fadeIn={false}
                        loading={"eager"}
                      />
                    </Link>
                  </LogoWrapper>
                  {location === "/" ? (
                    <h2 className={"subheading"}>{data.Subheading.subheading}</h2>
                  ) : null}
                </Wrapper>
              </MaxWidth>
            </BackgroundImage>
            <Navbar location={location} />
          </div>
        );
      }}
    />
  );
};

export default Header;

const HEADER_QUERY = graphql`
  query HeaderQuery {
    LocalHeaderImages: file(relativePath: { eq: "logo-inside-calpoly.png" }) {
      childImageSharp {
        fluid(maxWidth: 276, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    LocalHeaderBackground: file(relativePath: { eq: "bkgd-inside-calpoly-upd.png" }) {
      childImageSharp {
        fluid(maxWidth: 1840, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Subheading: sanityHomepageSettings {
      subheading
    }
  }
`;
