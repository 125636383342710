import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { Link } from "gatsby";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-bottom: 0;
    border-radius: 100%;
  }

  .logo-link {
    display: flex;
    align-items: center;
  }

  .name-link {
    text-decoration: none;

    &:visited {
      p {
        color: #497e6b;
      }
    }

    &:active,
    &:hover,
    &:focus {
      p {
        color: #164b38;
      }
    }
  }
`;

const AuthorName = styled.p`
  color: #497e6b;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;

  transition: color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const Date = styled.p`
  color: rgba(0, 0, 0, 0.54);

  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export default function Author({ author, publishedAt, style, onAuthorPage = false }) {
  const { image, name } = author;

  if (!onAuthorPage && author.slug.current !== undefined) {
    const slug = `/author/${author.slug.current}/`;

    return (
      <Wrapper style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link className={"logo-link"} to={slug} aria-label={`Learn more about ${name}`}>
            <Img fixed={image.asset.fixed} />
          </Link>
        </div>
        <div style={{ marginLeft: "0.5rem" }}>
          <Link className={"name-link"} to={slug}>
            <AuthorName>{name}</AuthorName>
          </Link>
          {publishedAt ? <Date>{publishedAt}</Date> : null}
        </div>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Img fixed={image.asset.fixed} alt={`${name} Large Logo`} />
        </div>
        <div style={{ marginLeft: "0.5rem" }}>
          <AuthorName>{name}</AuthorName>
          {publishedAt ? <Date>{publishedAt}</Date> : null}
        </div>
      </Wrapper>
    );
  }
}
