import { Link, graphql, StaticQuery } from "gatsby";
import React from "react";
import { MaxWidth } from "./ui-components";
import styled from "styled-components";

const FooterWrapper = styled.div`
  min-height: 85px;
  color: #bad5c9;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
    min-height: 135px;
  }
`;
const SecondaryNav = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;

  li {
    margin-bottom: 0;
    a {
      padding: 0.75rem 0;
      font-size: 15px;
      text-decoration: none;

      color: #bad5c9;

      transition: color 0.3s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

      &:visited {
        color: #bad5c9;
      }
      &:hover,
      &:active,
      &:focus {
        color: white;
      }
    }
  }
  li:not(:last-child) {
    &::after {
      content: "·";
      margin: 0 0.5rem;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 1rem 0;

    li {
      display: flex;

      &::after {
        display: none;
      }

      a {
        padding: 0.3rem 0;
      }
    }
  }
`;

const Footer = () => {
  return (
    <StaticQuery
      query={FOOTER_QUERY}
      render={(data) => {
        const navItems = data.NavItems.secondaryNav.map((item) => {
          return (
            <li key={item._key}>
              {item._type === "navItemReference" && !item.openInNewTab && (
                <Link to={`/${item.referencedDocument.slug.current}/`}>{item.label}</Link>
              )}

              {item._type === "navItemReference" && item.openInNewTab && (
                <a
                  href={`/${item.referencedDocument.slug.current}/`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {item.label}
                </a>
              )}

              {item._type === "navItemNonReference" && !item.openInNewTab && (
                <a href={`${item.url}`}>{item.label}</a>
              )}

              {item._type === "navItemNonReference" && item.openInNewTab && (
                <a href={`${item.url}`} target="_blank" rel="noreferrer noopener">
                  {item.label}
                </a>
              )}
            </li>
          );
        });

        return (
          <div style={{ backgroundColor: "#035642" }}>
            <MaxWidth>
              <FooterWrapper>
                <SecondaryNav>{navItems}</SecondaryNav>
              </FooterWrapper>
            </MaxWidth>
          </div>
        );
      }}
    />
  );
};

export default Footer;

const FOOTER_QUERY = graphql`
  query FooterQuery {
    NavItems: sanitySiteSettings {
      secondaryNav {
        ... on SanityNavItemNonReference {
          _key
          _type
          label
          url
          openInNewTab
        }
        ... on SanityNavItemReference {
          _key
          _type
          label
          openInNewTab
          referencedDocument {
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`;
