import { format, isFuture } from "date-fns";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getBlogUrl(publishedAt, slug) {
  return `/${slug.current || slug}/`;
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

export function truncateExcerpt(excerpt) {
  if (!excerpt) {
    return "";
  }

  if (excerpt.split(" ").length <= 28) {
    return excerpt;
  } else {
    return excerpt.split(" ").slice(0, 28).join(" ") + "...";
  }

  // 28 words
}

export function filterUniqueTags(postNodes) {
  const tags = postNodes.map((node) => {
    const postTags = node.tags.map((tag) => {
      return tag;
    });
    return postTags;
  });

  const flatTags = tags.flat();
  const uniqueTagNames = [...new Set(flatTags.map((tag) => tag.tagName))];
  const uniqueTags = uniqueTagNames.map((tagName) => {
    for (let index = 0; index < flatTags.length; index++) {
      if (flatTags[index].tagName === tagName) {
        return flatTags[index];
      }
    }
  });
  return uniqueTags;
}
